import React from 'react'
import * as styles from './FooterCard.module.css'
import SocialLinkList from '../../list/SocialLinkList/SocialLinkList'
import Card from '../Card/Card'

export default function FooterCard({className=""}) {
    return (
        <Card className={`${styles.card} ${className}`}>
            <img 
                src={`/images/logo.svg`}
                height={40}
                width={52}
                alt="logo of raysummee"
            />
            <h3>Raysummee</h3>
            <span className={styles.copyright}>Raysummee © {new Date().getFullYear()}</span>
            <SocialLinkList/>
        </Card>
    )
}
