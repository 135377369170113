import React from 'react'
import * as styles from './SocialLinkList.module.css'
import { SocialIcon } from 'react-social-icons';
import { graphql, useStaticQuery } from 'gatsby';

export default function SocialLinkList() {
    const data = useStaticQuery(graphql`
      query MyQuery {
        mdx(slug: {eq: "socials"}) {
          frontmatter {
            social {
              network
              url
            }
          }
        }
      }
    `)
    const social_list = data.mdx.frontmatter.social;
    return (
        <div className={styles.social_list}>
            {
                social_list.map((social, ind)=>{
                    return <SocialIcon target='_blank' rel='noopener noreferrer' className={styles.social_icon} key={ind} {...social}/>
                })
            }
        </div>
    )
}
